<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <b-row>
        <b-col md="9">
          <b-card>
            <h4>
              <strong>Howdy Administrator!</strong>
            </h4>
            <p>
              Welcome to the Malacca Trust Wuwungan Insurance Website Administrator Page
              You are on the PROTECTED page of the Malacca Trust Wuwungan Insurance Website Content Management system.
            </p>
            <ol>
              <li>Save and secure your Username and Password to avoid things that are not desirable.</li>
              <li>Prepare the material or content that will be updated first.</li>
              <li>Also prepare photos, videos or other related material, to facilitate the updating process.</li>
              <li>Use the latest version of the browser to get the compatibility features in this backoffice.</li>
            </ol>
            <p>
              Web Developer Contact:<br>
              Email:hello@lingkar9.com<br>
              Phone: +62-21-744-6929
            </p>
          </b-card>
        </b-col>
        <b-col md="3">
          <div class="card_dashboard bg_card_1 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.article}}</h3>
                  <p>Articles Published</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_2 mb-3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.news}}</h3>
                  <p>News Published</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card_dashboard bg_card_3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="icon-briefcase"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{dataCard.career}}</h3>
                  <p>Open Vacancies</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="6">
          <b-card no-body :header-class="dataArticle.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Most Viewed Articles</h5>
                <b-button class="btn-rounded" variant="info"
				v-if="dataArticle.length"
				:to="{ name: 'ArticleList', query: {mainCategory : 'ARTICLE'} }"
				>
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
						<b-table
							responsive
							:fields="blogFields"
							:items="dataArticle"
							striped
							bordered
							class="mb-0"
							show-empty
						>
						<template #cell(num)="data">{{ data.index += 1 }}</template>
							<template #cell(title)="data">
								<div class="wrap_title_post">
									<h3>
										<a href="javascript:;">{{ data.item.ma_title_id }}</a>
									</h3>
									<ul>
										<li><i class="icon-user"></i> {{ data.item.ma_author }}</li>
										<li><i class=" ti-calendar"></i> {{ data.item.ma_date | moment("DD MMMM YYYY, HH:mm") }}</li>
										<li><i class="icon-eye"></i> {{ data.item.ma_viewer }}</li>
									</ul>
								</div>
							</template>
							<template #cell(category)="data">
								<div><strong class="text-primary">{{ data.item.mac_category_id }}</strong></div>
								<small>Article</small>
							</template>
						</b-table>
          </b-card>
        </b-col>
        <b-col lg="6">          
          <b-card no-body :header-class="dataNews.length ? 'card-dashboard-item' : 'card-dashboard-noitem'">
            <template #header>
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="card-title">Most Viewed News</h5>
                <b-button class="btn-rounded" variant="info"
					:to="{ name: 'ArticleList', query: {mainCategory : 'NEWS'} }"
					v-if="dataNews.length"
				>
                  View All <i class="fa fa-arrow-circle-right ml-2"></i>
                </b-button>
              </div>
            </template>
						<b-table
							responsive
							:fields="blogFields"
							:items="dataNews"
							striped
							bordered
							class="mb-0"
							show-empty
						>
							<template #cell(num)="data">{{ data.index += 1 }}</template>
							<template #cell(title)="data">
								<div class="wrap_title_post">
									<h3>
										<a href="javascript:;">{{ data.item.ma_title_id }}</a>
									</h3>
									<ul>
										<li><i class="icon-user"></i> {{ data.item.ma_author }}</li>
										<li><i class=" ti-calendar"></i> {{ data.item.ma_date | moment("DD MMMM YYYY, HH:mm") }}</li>
										<li><i class="icon-eye"></i> {{ data.item.ma_viewer }}</li>
									</ul>
								</div>
							</template>
							<template #cell(category)="data">
								<div><strong class="text-primary">{{ data.item.mac_category_id }}</strong></div>
								<small>Article</small>
							</template>
						</b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'
import Gen from '@/libs/Gen'
const _ = global._

export default {
  extends: GlobalVue,
  data() {
    return {
			dataCard:{},
			dataArticle:[],
			dataNews:[],	
			blogFields: [
				{ key: 'num', label: '#' },
				{ key: 'title'},
				'category',
			],
      dataSaham:[],

      chartTime: 'D',
      chartTimeModes: [
        { label: 'Today', value: 'D' },
        { label: 'This Week', value: 'W' },
        { label: 'This Month', value: 'M' }
      ],
    }
  },
  methods: {
    getStock(type){
      this.restAPI({type : type})
    },
    restAPI(params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  mounted() {
    this.getStock(this.chartTime)
  },
  watch:{
    $route(){
      this.getStock(this.chartTime)
    }
  },
}
</script>